import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticlePreview from "../components/articlePreview"

const Articles = ({ data }) => {
  const articles = data.allNodeArticle.nodes
  console.log("articles", articles)
  return (
    <Layout>
      <SEO title="Kalyan Feeds" />
      <div className="row backNavGreen">
        <div className="col-md-12" id="navCrumb">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="row" id="breadCrumb">
                  <div className="col-md-12">
                    <span>
                      <Link to="/">Home</Link>
                      &nbsp;/&nbsp;
                      <Link to="/articles">Articles</Link>
                    </span>
                  </div>
                </div>
                <div className="row" id="headCrumb">
                  <div className="col-md-12">
                    <h4>Articles</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {/* Test Comment */}
          {articles.map(article => (
            <div className="col-md-3">
              <ArticlePreview
                key={article.id}
                articleId={article.drupal_id}
                title={article.title}
                path={article.path.alias}
                tag={article.relationships.field_tags[0].name}
                image={
                  article.relationships.field_image.localFile.childImageSharp
                    .fluid
                }
                alt={article.field_image.alt}
                summary={
                  article.body.summary
                    ? article.body.summary
                    : article.body.processed.substring(0, 300)
                }
              />
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

Articles.propTypes = {
  data: PropTypes.object.isRequired,
}

export const data = graphql`
  {
    allNodeArticle(sort: { fields: created, order: DESC }, limit: 1000) {
      nodes {
        id
        drupal_id
        title
        created
        body {
          processed
          summary
        }
        path {
          alias
        }
        field_image {
          alt
        }
        relationships {
          field_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          field_tags {
            name
          }
        }
      }
    }
  }
`

export default Articles
